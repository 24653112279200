import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import Link from 'next/link';
import { useRouter } from 'next/router';

const navigation = {
  generators: [
    { name: 'PDF to Flashcards', target: '_self', href: '/pdf-to-flashcards' },
    {
      name: 'PPTX to Flashcards',
      target: '_self',
      href: '/pptx-to-flashcards'
    },
    {
      name: 'DOCX to Flashcards',
      target: '_self',
      href: '/docx-to-flashcards'
    },
    {
      name: 'Image to Flashcards',
      target: '_self',
      href: '/image-to-flashcards'
    },
    {
      name: 'YouTube to Flashcards',
      target: '_self',
      href: '/youtube-to-flashcards'
    },
    // {
    //   name: 'YouTube to Flashcards',
    //   target: '_self',
    //   href: '/youtube-to-flashcards'
    // },
    {
      name: 'Wikipedia to Flashcards',
      target: '_self',
      href: '/wikipedia-to-flashcards'
    }
  ],
  features: [
    {
      name: 'AI Practice Tests',
      description: 'Generate exams with score reports',
      href: '/ai-practice-tests'
    },
    {
      name: 'AI Study Guides',
      description: 'Focus on what you need to know',
      href: '/ai-study-guides'
    },
    {
      name: 'AI Image Occlusion',
      description: 'Occlude images from any file',
      href: '/ai-image-occlusion'
    },
    {
      name: 'Spaced Repetition',
      description: 'The fastest way to memorize',
      href: '/spaced-repetition'
    },
    {
      name: 'AI Image Occlusion Anki Add-on',
      target: '_self',
      href: '/anki-ai-image-occlusion'
    },
    {
      name: 'Practice Test Generator Anki Add-on',
      target: '_self',
      href: '/anki-practice-tests'
    },
    {
      name: 'Examples',
      target: '_self',
      href: '/examples'
    }
  ],
  company: [
    { name: 'Pricing', target: '_self', href: '/pricing' },
    { name: 'About', target: '_self', href: '/about' },
    { name: 'FAQ', target: '_self', href: '/faq' },
    { name: 'Contact', target: '_blank', href: 'mailto: hello@limbiks.com' },
    { name: 'Support', target: '_blank', href: 'mailto: support@limbiks.com' }
  ],
  legal: [
    {
      name: 'Claim',
      target: '_blank',
      href: 'https://www.termsfeed.com/live/c8a0b3f3-3d48-475c-9dc8-67d0cbe8460c'
    },
    {
      name: 'Privacy',
      target: '_blank',
      href: 'https://www.termsfeed.com/live/6c6eb58b-47bc-406c-9b85-f2ed7719cdea'
    },
    {
      name: 'Terms',
      target: '_blank',
      href: 'https://www.websitepolicies.com/policies/view/6SscQOIG'
    }
  ],
  social: [
    {
      name: 'Reddit',
      href: 'https://reddit.com/r/limbiks',
      target: '_blank',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"
          />{' '}
        </svg>
      )
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/limbiks_official',
      target: '_blank',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      )
    },
    {
      name: 'X',
      href: 'https://twitter.com/LimbiksOfficial',
      target: '_blank',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      )
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@limbiks',
      target: '_blank',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      )
    }
  ]
};

export default function Footer() {
  const router = useRouter();
  const { trackEvent, analyticsInstance } = useAnalytics();

  return (
    <footer aria-labelledby="footer-heading" className="bg-white">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img alt="Company name" src="/orange-logo.png" className="h-7" />
            <p className="text-sm leading-6 text-gray-600">
              Limbiks - Optimize your study routine
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target={item.target}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Generators
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.generators.map((item) => (
                    <li key={item.name}>
                      {item.href.startsWith('/') ? (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <a
                          href={item.href}
                          target={item.target}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Features
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.features.map((item) => (
                    <li key={item.name}>
                      {item.href.startsWith('/') ? (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <a
                          href={item.href}
                          target={item.target}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      {item.href.startsWith('/') ? (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <a
                          href={item.href}
                          target={item.target}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target={item.target}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">
            &copy; 2024 Limbiks Solutions, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );

  return (
    <footer className="bg-white">
      <div className="container mx-auto px-8">
        <div className="flex w-full flex-col py-6 md:flex-row">
          <div className="mb-6 flex-1 text-black">
            <div>
              <a
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_company')
                }
                className="text-2xl font-bold text-gray-800 no-underline hover:no-underline lg:text-4xl"
                href="#"
              >
                LIMBIKS
              </a>
              <div className="mt-4 text-gray-500">Limbiks Solutions, LLC</div>
            </div>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Links</p>
            <ul className="list-reset mb-6">
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_examples')
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/examples'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    Examples
                  </span>
                </Link>
              </li>
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_about')
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/about'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    About
                  </span>
                </Link>
              </li>
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_blog')
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/faq'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    FAQ
                  </span>
                </Link>
              </li>
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_pricing')
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/pricing'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    Pricing
                  </span>
                </Link>
              </li>
              <li
                onClick={() =>
                  trackEvent(
                    analyticsInstance,
                    'home_footer_click_occlusion_addon'
                  )
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/anki-ai-image-occlusion'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    Anki AI Image Occlusion
                  </span>
                </Link>
              </li>
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_footer_click_test_addon')
                }
                className="mt-2 mr-2 inline-block md:mr-0 md:block"
              >
                <Link href={'/anki-practice-tests'} legacyBehavior>
                  <span className="cursor-pointer text-gray-800 no-underline hover:text-primary hover:underline">
                    Anki Practice Test Generator
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Legal</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  target="_blank"
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_terms')
                  }
                  href="https://www.websitepolicies.com/policies/view/6SscQOIG"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Terms and Conditions
                </a>
              </li>
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_privacy')
                  }
                  target="_blank"
                  href="https://www.termsfeed.com/live/6c6eb58b-47bc-406c-9b85-f2ed7719cdea"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Privacy
                </a>
              </li>
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(
                      analyticsInstance,
                      'home_footer_click_disclaimer'
                    )
                  }
                  target="_blank"
                  href="https://www.termsfeed.com/live/c8a0b3f3-3d48-475c-9dc8-67d0cbe8460c"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Disclaimer
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Social</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_reddit')
                  }
                  target="_blank"
                  href="https://reddit.com/r/limbiks"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Reddit
                </a>
              </li>
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_twitter')
                  }
                  target="_blank"
                  href="https://twitter.com/LimbiksOfficial"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Twitter
                </a>
              </li>
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_instagram')
                  }
                  target="_blank"
                  href="https://www.instagram.com/limbiks_official"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Company</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_contact')
                  }
                  href="mailto: hello@limbiks.com"
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Contact
                </a>
              </li>
              <li className="mt-2 mr-2 inline-block md:mr-0 md:block">
                <a
                  href="mailto: support@limbiks.com"
                  onClick={() =>
                    trackEvent(analyticsInstance, 'home_footer_click_support')
                  }
                  className="text-gray-800 no-underline hover:text-primary hover:underline"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
